import './css/App.css';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next'; // Asegúrate de importar i18n
import enFlag from './en-flag.jpg'; // Ruta de la bandera de España
import esFlag from './es-flag.png'; // Ruta de la bandera de EE. UU.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAndroid } from '@fortawesome/free-brands-svg-icons';

function App() {
  const { t } = useTranslation(); 

  const GoDown = () => {
    const element = document.querySelector('.Projects');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Función para cambiar el idioma
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="App">
      <header className="App-header">
        {/* Botones de idiomas en la esquina superior derecha */}
        <div className="language-buttons">
          <img 
            src={esFlag} 
            alt="Español" 
            onClick={() => changeLanguage('es')} 
            className="flag-button" 
          />
          <img 
            src={enFlag} 
            alt="English" 
            onClick={() => changeLanguage('en')} 
            className="flag-button" 
          />
        </div>

        <h1>Luis Ruiz Nuñez</h1>
        <h2>{t('softwareEngineer')}</h2>
        <img src="/PixelArt.jpeg" alt="Foto PixelArt" />
        <p>{t('welcome')}</p>
        <button className="cta-button" onClick={GoDown}>{t('viewMyWork')}</button>
        <nav className="App-nav"> 
          <a href="https://www.linkedin.com/in/luis-ruiz-nu%C3%B1ez-b69569185" target="_blank" rel="noreferrer" className="social-link">
            <img src="https://cdn-icons-png.flaticon.com/512/174/174857.png" alt="LinkedIn" className="social-icon" />
          </a>
          <a href="https://github.com/LuiRui07" target="_blank" rel="noreferrer" className="social-link">
            <img src="https://cdn-icons-png.flaticon.com/512/733/733553.png" alt="GitHub" className="social-icon" />
          </a>
        </nav>
      </header>
      
      <section className="Projects">
        <h2>{t('projects')}</h2>
        <div className="project-grid">
          <a href="https://github.com/LuiRui07/TFG" target="_blank" rel="noreferrer" className="project-card-link">
            <div className="project-card">
              <h3>
              <FontAwesomeIcon icon={faAndroid} style={{ marginRight: '8px' }}/>        
              {t('project1')}
              </h3>
              <p>{t('project1Description')}</p>
            </div>
          </a>
          <a href="https://github.com/LuiRui07/elRastro" target="_blank" rel="noreferrer" className="project-card-link">
            <div className="project-card">
            <h3>
              <img src="react-icon.svg" alt="React Logo" style={{ width: '24px', height: '24px', marginRight: '8px' }} />
              {t('project2')}
            </h3>
              <p>{t('project2Description')}</p>
            </div>
          </a>
          <a href="https://github.com/LuiRui07/SkyBank" target="_blank" rel="noreferrer" className="project-card-link">
            <div className="project-card">
              <h3>
              <img src="./spring-icon.svg" alt="Swift Logo" style={{ width: '24px', height: '24px', marginRight: '8px' }} />
              {t('project3')}</h3>
              <p>{t('project3Description')}</p>
            </div>
          </a>
          <a href="https://github.com/LuiRui07/iVision" target="_blank" rel="noreferrer" className="project-card-link">
            <div className="project-card">
              <h3>
              <img src="./swift.svg" alt="Swift Logo" style={{ width: '24px', height: '24px', marginRight: '8px' }} />
              {t('project4')}</h3>
              <p>{t('project4Description')}</p>
            </div>
          </a>
        </div>
      </section>

      <footer className="App-footer">
        <p>{t('contact')} <a href="mailto:luisruiznunez@gmail.com">{t('email')}</a></p>
        <p>{t('copyright')}</p>
      </footer>
    </div>
  );
}

export default App;
